import { postBaseSchema } from "@/renderer/schema";
import { z } from "zod";

export const BlogCard = ({
  post,
}: {
  post: z.infer<typeof postBaseSchema>;
}) => (
  <a href={`/blog/${post.id}/`}>
    <div className="relative h-[250px] w-full p-4">
      <img
        alt={post.title}
        src={post.thumbnail}
        className="rounded-3xl object-cover fill-img z-0"
      />
      {post.tag && post.tag !== "" ? (
        <small className="font-bold z-10 bg-white rounded-3xl uppercase flex relative w-fit px-2 py-1">
          {post.tag}
        </small>
      ) : null}
    </div>
    <h2 className="font-bold text-xl my-2">{post.title}</h2>
    {post.description && post.description !== "" ? (
      <p>{post.description}</p>
    ) : null}
  </a>
);
